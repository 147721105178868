<template>
    <div class="d-flex flex-column flex-grow-1 fill-height">
        <v-row
            v-if="loading"
            class="ma-2"
            justify="center"
            align-content="center"
        >
            <v-progress-circular
                color="primary"
                size="75"
                indeterminate
            ></v-progress-circular>
        </v-row>
        <v-row
            v-if="error"
            class="ma-2"
            justify="center"
            align-content="center"
        >
            <v-alert type="error">
                {{ error }}
            </v-alert>
        </v-row>

        <ClustersList v-if="loaded"
            :clusters="filterItems"
            :scrollerHeight="scrollerHeight - marginTop"
            @selected="selectCluster"
        ></ClustersList>
    </div>
</template>

<script>
import Cluster from '../../viewmodels/cluster.js'
import { mapActions, mapMutations, mapState } from 'vuex'
import * as clusterActions from '../../store/actions/cluster'
export const ClustersList = () => import("./ClustersList");

const sortCluster = (a, b) => {
           if (a.Gruppo < b.Gruppo) return -1;
           if (a.Gruppo > b.Gruppo) return 1;
           return 0;
        }

export default {

    props: ["id", "scrollerHeight"],

    components: {
        ClustersList
    },

    computed: {
        ...mapState({
            clusters: state => state.clusterModule.clusters,
            searchString: state => state.filterModule.clustersFilter.text,
            loading: state => state.clusterModule.loading,
            loaded: state => state.clusterModule.loaded,
            error: state => state.clusterModule.error
        }),

        marginTop() {
            return '0'
        },

        filterItems() {
            return this.clusters.filter(item => {
                return item.Gruppo.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1
            }).sort(sortCluster)
        }
    },

    methods: {
        ...mapActions({
            loadClusters: clusterActions.CLUSTER_GET_CLUSTERS,
            loadCluster: clusterActions.CLUSTER_GET_CLUSTER,
            saveCluster: clusterActions.CLUSTER_SET_CLUSTER
        }),

        ...mapMutations({
            updateCluster: clusterActions.CLUSTER_ADD_OR_MODIFY_CLUSTER
        }),

        selectCluster(item) {
            this.loadCluster(item.IDGruppo)
                .then(result => {
                    const ids = result.map(x => x.IDCliente);

                    const cluster = new Cluster();
                    const data = cluster.setGruppo({
                        IdConsorzio: parseInt(this.id),
                        IdGruppo: item.IDGruppo,
                        NomeGruppo: item.Gruppo,
                        IdClienti: ids
                    })

                    this.$formdialog
                        .open({
                            type: "",
                            title: "Modifica Cluster",
                            data: data,
                            formName: "AddEditCluster",
                            fullScreen: true
                        })
                        .then(async response => {
                            this.$formdialog.showProgress(true);
                            const result = await this.saveCluster(response)
                            this.updateCluster(result)
                        })
                        .catch(err => {
                            console.error(err);
                        })
                        .finally(() => {
                            this.$formdialog.showProgress(false);
                            this.$formdialog.destroy();
                        });
                })
                .catch(err => {
                    console.error(err);
                })
        }
    },

    mounted() {
        this.$nextTick(async function() {
            await this.loadClusters(this.id);
        });
    }

}
</script>

<style>

</style>
